import { Button } from 'antd';

const Footer = () => {
  return (
    <footer className="flex flex-col items-center pb-6 mt-16 w-full">
      <div className="flex flex-col px-5 w-full md:px-24 lg:px-36">
        
        <section className="flex flex-col justify-center pt-12 pb-8 w-full">
          <h2 className="sm:text-4xl text-2xl font-bold leading-relaxed text-gray-800">
            Follow us for the latest updates
          </h2>
          <div className="flex flex-wrap sm:gap-4 items-start mt-8 w-full justify-between">
            {[
              { 
                name: 'Facebook', 
                src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/35323a723d0fcdc7e91ad5bb74f64cbc78a43f646321d86b84a684a237d5f92b?placeholderIfAbsent=true&apiKey=0d95acea82cc4b259a61e827c24c5c6c',
                href: 'https://www.facebook.com/tracecorp?mibextid=ZbWKwL'
              },
              { 
                name: 'LinkedIn', 
                src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/de9476afb0ddbf02979beb2df4c70c60fa3997f82dfb96adba3107154b92dffc?placeholderIfAbsent=true&apiKey=0d95acea82cc4b259a61e827c24c5c6c',
                href: '#'
              },
              { 
                name: 'Twitter', 
                src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/9fb1f97d2cc1e3214d494105680eaab0f6899633d0edf94c18be7e688ee1fb1c?placeholderIfAbsent=true&apiKey=0d95acea82cc4b259a61e827c24c5c6c',
                href: '#'
              },
              { 
                name: 'YouTube', 
                src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/f62f6d3aef7d2520cd7573a89ee1452653fc8375aeafc1c3e9781f99d286fc38?placeholderIfAbsent=true&apiKey=0d95acea82cc4b259a61e827c24c5c6c',
                href: '#'
              }
            ].map((social, index) => (
              <a
                key={index}
                href={social.href}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-4 w-full md:w-[48%] lg:w-[23%]"
                aria-label={`Follow us on ${social.name}`}
              >
                <div className="flex items-center justify-center w-12 h-12 bg-custom-green rounded-full">
                  <img
                    loading="lazy"
                    src={social.src}
                    alt={social.name}
                    className="w-6 h-6"
                  />
                </div>
                <div className="flex flex-col">
                  <h4 className="text-lg font-bold text-gray-800">
                    {social.name}
                  </h4>
                  <div className="text-base text-gray-500">
                    Tracecorp solutions
                  </div>
                </div>
              </a>
            ))}
          </div>
        </section>

        {/* Footer Sections */}
        <section className="flex flex-col md:flex-row pt-8 pb-6 border-t border-gray-200 text-base text-gray-500 w-full flex-wrap justify-between">
          <div className="flex flex-col mb-6 w-full md:w-[48%] lg:w-[23%]">
            <h4 className="text-xl font-bold text-gray-800">Tracecorp</h4>
            <div className="mt-2">© 2009 - 2024. All Rights Reserved.</div>
            <div className="mt-8">
              <div className="mb-2">Address: Plot 32 Lumumba Avenue</div>
              <div className="mb-2">P.O Box: 155145</div>
              <div className="mb-2">Tel: +256 756770277</div>
              <div>Email: info@tracecorpsolutions.com</div>
            </div>
          </div>
          <div className="flex flex-col mb-6 w-full md:w-[48%] lg:w-[23%]">
            <h4 className="font-bold text-gray-800">Industries</h4>
            <div className="mt-2 mb-2">Banking</div>
            <div className="mb-2">High Tech</div>
            <div className="mb-2">Insurance</div>
            <div className="mb-2">Travel & Logistics</div>
            <div>Energy resources & Utilities</div>
          </div>
          <div className="flex flex-col mb-6 w-full md:w-[48%] lg:w-[23%]">
            <h4 className="font-bold text-gray-800">Services</h4>
            <div className="mt-2 mb-2">Artificial Intelligence</div>
            <div className="mb-2">Consulting</div>
            <div className="mb-2">Mobile App Development</div>
            <div className="mb-2">UI/UX Design</div>
            <div>Web App Development</div>
          </div>
          <div className="flex flex-col mb-6 w-full md:w-[48%] lg:w-[23%]">
            <h4 className="font-bold text-gray-800">Products</h4>
            <div className="mt-2 mb-2">Accounting System</div>
            <div className="mb-2">Water Billing System</div>
            <div className="mb-2">CRM System</div>
            <div className="mb-2">Human Resource System</div>
            <div>Trace Pay</div>
          </div>
        </section>
      </div>
    </footer>
  );
}

export default Footer;
